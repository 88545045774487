import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  Container,
  Header as SUIHeader,
  Grid,
  Button as SUIButton,
  Image,
} from "semantic-ui-react"
import ReactPlayer from "react-player"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Hero from "../../components/hero"
import TextContainer from "../../components/text-container"

const GridRow = styled(Grid.Row)`
  @media only screen and (min-width: 1200px) {
    padding: 6em 0em 0em 0em !important;
  }
`

const GridColumn = styled(Grid.Column)`
  padding: 0em 5em !important;
  text-align: justify !important;
`

const Header = styled(SUIHeader)`
  font-size: ${props =>
    (props.as === "h1" && "2.6em") ||
    (props.as === "h2" && "2.2em") ||
    (props.as === "h3" && "1.5em")};
`

const Button = styled.a`
  width: 100%;
  padding: 2em 0em !important;
  margin-top: 2em !important;
  text-transform: uppercase !important;
  background: #d7203d !important;
  color: white !important;
`

const IndexPage = () => (
  <Layout>
    <SEO title="HFE and MSI" keywords={[`host family english`, `hfe`]} />
    <Grid
      style={{
        marginLeft: "0em",
        marginRight: "0em",
      }}
    >
      <TextContainer>
        <p>
          When Host Family English (HFE) decided to look for a global partner to
          help launch their Mentor-led English programme, there were a few
          organisations they could have gone to. Host Family English’s mission
          is to maximise the homestay experience for a students’ immersed
          language learning and create a safe and secure environment for
          learning and language acquisition. This resonates very closely with
          <a href="https://mystayinternational.com/">
            MyStay International (MSI)
          </a>
          , the largest provider of Host Families to students in Australia, who
          are also focussed on the entire student experience and quality of
          service.
        </p>
        <p>
          This partnership brings quality, security, expertise, understanding
          and above all, experience in dealing with international students,
          their needs and expectations from both a mentoring and also a host
          family environment.
        </p>
        <p>
          Today{" "}
          <a href="https://mystayinternational.com/">MyStay International</a>{" "}
          has operations throughout Australia, France, the UK and the USA; and
          has just opened in China.
        </p>
        <p>
          Host Family English is proud to partner with MyStay International.
        </p>
      </TextContainer>
    </Grid>
  </Layout>
)

export default IndexPage
